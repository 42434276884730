// 高德地图
import VueAMap, { initAMapApiLoader, lazyAMapApiLoaderInstance } from '@vuemap/vue-amap';
import '@vuemap/vue-amap/dist/style.css'

initAMapApiLoader({
	// key: '02a1cad575b550cbfc69078a57087cc9', // 谭良友的
	// securityJsCode: '9d575ba8d536c4d71cd7be4a0bbc4393', // 新版key需要配合安全密钥使用
	key: '90ad4efda68400ccc961d0aa4ef71dcd', // 陈璞的
	securityJsCode: '51c0d376ddf8434770436f15efb30efa', // 新版key需要配合安全密钥使用
	plugins: [
		'AMap.Autocomplete', 'AMap.Geocoder', 'AMap.DragRoute', 'AMap.MoveAnimation'
	],
	//Loca:{
	//  version: '2.0.0'
	//} // 如果需要使用loca组件库，需要加载Loca
})

// lazyAMapApiLoaderInstance.then(() => {
// 	// your code ...
// 	this.map = new AMap.Map('amapContainer', {
// 		center: new AMap.LngLat(113.539056, 22.331014),
// 	});
// });

export default VueAMap

